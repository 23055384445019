<script setup lang="ts">
const DEFAULT_SIZE = 20
type Props = {
  color?: string
  size?: number
}
const props = withDefaults(defineProps<Props>(), {
  color: '#FFFFFF',
  size: DEFAULT_SIZE,
})
const scale = computed(() => {
  return props.size / DEFAULT_SIZE
})
</script>
<template>
  <svg
    :width="size"
    :height="size"
    :view-box="`0 0 ${size} ${size}`"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      :transform="`scale(${scale})`"
      :stroke="color"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <path d="M18 2L2 18" />
      <path d="M2 2L18 18" />
    </g>
  </svg>
</template>
